<template>
  <div class="auth-wrapper auth-v2">
    <div class="wrapper__app-bar-i18n">
      <app-bar-i18n></app-bar-i18n>
    </div>

    <div class="auth-inner">
      <GeneralLogo />

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="
                require(`@/assets/images/misc/mask-v2-${
                  $vuetify.theme.dark ? 'dark' : 'light'
                }.png`)
              "
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ t("Signin") }}! 👋🏻
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="loginForm" @submit.prevent="handleFormSubmit">
                    <FormTextField
                      v-model="formData.email"
                      :label="t('Email')"
                      :placeholder="t('Email')"
                      :rules="[validators.required, validators.emailValidator]"
                      class="mb-6"
                    />

                    <FormTextFieldPassword
                      v-model="formData.password"
                      :label="t('Password')"
                      :placeholder="t('Password')"
                      :rules="[validators.required]"
                      class="mb-6"
                    />

                    <div
                      class="d-flex align-center justify-space-between flex-wrap"
                    >
                      <p></p>

                      <!-- forget link -->
                      <router-link
                        :to="{ name: 'auth-forgot-password' }"
                        class="ms-3"
                      >
                        {{ t("ForgotPass") }}
                      </router-link>
                    </div>

                    <v-btn block color="primary" type="submit" class="mt-6">
                      {{ t("Start") }}
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text
                  class="d-flex align-center justify-center flex-wrap mt-2"
                >
                  <p class="mb-0 me-2">{{ t("AskForgotPass") }}</p>
                  <router-link :to="{ name: 'auth-register' }">
                    {{ t("Signup") }}
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import GeneralLogo from "@/components/general/Logo";
import FormTextField from "@/components/forms/TextField";
import FormTextFieldPassword from "@/components/forms/TextFieldPassword";
import AppBarI18n from "@core/layouts/components/app-bar/AppBarI18n";

import {
  ref,
  getCurrentInstance,
  reactive,
  onUpdated,
} from "@vue/composition-api";
import { required, emailValidator } from "@core/utils/validation";
import api from "@/services";
import { useRouter } from "@core/utils";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { loadLanguageAsync } from "@/plugins/i18n";

export default {
  components: {
    GeneralLogo,
    FormTextField,
    FormTextFieldPassword,
    AppBarI18n,
  },
  setup() {
    const vm = getCurrentInstance().proxy;
    const { t } = useUtilsI18n();
    const signupErrorMessage = ref(t("SigninError"));

    const { router } = useRouter();

    const loginForm = ref(null);
    const formData = reactive({
      email: null,
      password: null,
    });


    const handleFormSubmit = async () => {
      const isFormValid = loginForm.value.validate();
      if (!isFormValid) return;

      try {
        const { data: response } = await api.login(formData);
        const { access_token: accessToken, user } = response;
        const { country } = user;

        if (accessToken) {
          const defaultLocale = country
            ? country.code.toString().toLowerCase()
            : "es";
          loadLanguageAsync(defaultLocale);

          localStorage.setItem("accessToken", accessToken);
          localStorage.setItem("userData", JSON.stringify(user));
          router.push("/");
        }
      } catch (error) {
        vm.$alert(signupErrorMessage.value, null, "error");
      }
    };

    onUpdated(() => {
      signupErrorMessage.value = t("SigninError");
    });

    return {
      validators: {
        required,
        emailValidator,
      },

      // Template Refs
      loginForm,
      formData,

      handleFormSubmit,

      t,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@core/preset/preset/pages/auth.scss";
</style>
